* {
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Khula', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.leftIcon {
  position: absolute;
  left: 0;
  top: 6%;
  animation: moveDiv 4s infinite;
}

.rightIcon {
  position: absolute;
  right: 0;
  top: 23%;
  animation: moveDiv 4s infinite;
}

.rightIconTop {
  position: absolute;
  right: 21%;
  top: 0;
  animation: moveDiv 4s infinite;
}

@keyframes moveDiv {
  50% {
    transform: translate(0, 50%);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveDiv1 {
  50% {
    transform: translate(0, 30%);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes moveDiv2 {
  50% {
    transform: translate(0, 45%);
  }
  100% {
      transform: translate(0, 0);
  }
}

/* src/Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000; /* Change color as needed */
}

.loading-logo {
  width: 100px; /* Adjust size as needed */
  height: 100px;
  animation: spin 0.5s linear infinite;
}

.loading-text {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333; /* Change color as needed */
}

@keyframes spin {
  50% { transform: translateY(10px); }
  100% { transform: translateY(0); }
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./../public/assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
}